
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
// import { screen } from "@/common/screen/screen";
import CashierOrderListAPI from "@/common/api/financeManage/cashierOrderList";
import { CommonApi } from "@/common/api/common";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { utils } from "xlsx";

import gallery from "@/components/gallery.vue";

import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";


export const pageApiList = getPageAPIs(CashierOrderListAPI)

const payMode2ch: string[] = [
  '',
  '',
  '预付费',
  '美团券',
  '抖音券',
  '畅打券'
]


const cashierPay2ch: string[] = [
  '未知',
  '微信支付',
  '支付宝支付',
  '现金结账',
  '会员支付',
  '单店卡支付'
]

const cashier2Pay2ch: string[] = [
  '',
  '微信支付',
  '支付宝支付',
  '现金结账',
  '会员支付',
  '单店卡支付'
]

// 审核状态 2:审核中3:审核通过5:审核未通过
// const withdrawAccountAudit2Text: any = ["", "", "审核中", "审核通过", "", "审核未通过"];

const defaultForm: any = {
  order_no: '',
  venue_id: "",
  venue: {
    id: "",
    name: "",
  },
  pay_phone: '',
  time: []
}

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};


@Component({
  components: {
    gallery,
    ZlSelect,
    ZlOption,
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
  filters: {
    // refundAuditStatusText(statusNum: any): any {
    //   return withdrawAccountAudit2Text[statusNum] || "未知状态";
    // },
    orderTablePayType(pay_type: number) {
      return payMode2ch[pay_type]
    },

    orderTotalPayMethod(order: any) {
      const {
        cashier_pay,
        cashier_pay2
      } = order || {}

      if (cashier_pay2 == 0) {
        return cashierPay2ch[cashier_pay]
      } else {
        return `${cashierPay2ch[cashier_pay]} + ${cashier2Pay2ch[cashier_pay2]}`
      }
    }
  },
})
export default class RecommandWithdrawAccountAudit extends Vue {
    /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: '店铺名称',
      field: 'shop_name'
    },
    {
      label: '订单号',
      field: 'order_no'
    },
    {
      label: '球桌信息',
      field: 'table-pool-info'
    },
    {
      label: '商品信息',
      field: 'table-goods-info'
    },
    {
      label: '助教信息',
      field: 'table-assistant-info'
    },
    {
      label: '手机号',
      field: 'pay_phone'
    },
    {
      label: '支付方式',
      field: 'table-pay-method'
    },
    {
      label: '总金额（元）',
      field: 'total_price'
    },
    {
      label: '实际支付金额（元）',
      field: 'actual_total_price'
    }
  ];
  /** end 移动端相关属性 方法 */

  private form: any = {
    // // type:  0, // 问题类型
    // user_id: "", // 用户ID
    // nickname: "", // 助教昵称
    // is_status: 1, // 状态
    // time: [], // 时段选择
  };
  private deepLoopUtil: any;

  constructor() {
    super()

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false),
    }
  }
  private cashierOrderListApi: any = new CashierOrderListAPI();
  private created(): void {
    // this.getProblemTypes();
    this.search();
  }
  private getTable(): void {
   
    this.cashierOrderListApi.getList(
      {
        ...this.getWithdrawAccountAuditParams()
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.pageNo = res.data.page || this.tablePage.pageNo;
        this.tablePage.pageSize = res.data.prepage || this.tablePage.pageSize;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }
  // private problemList: any[] = [
  //   ...problemList
  // ];

  // 审核状态 1待审核 2审核通过 3驳回
  // private statusList: any[] = [
  //   {
  //     label: "审核中",
  //     value: 2,
  //   },
  //   {
  //     label: "审核通过",
  //     value: 3,
  //   },
  //   {
  //     label: "审核未通过",
  //     value: 5,
  //   },
  // ];

  // private systemTypaName: any[] = ["未知", "安卓", "IOS", "小程序"];

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

 
  private images: any = [];
 

  private getWithdrawAccountAuditParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;


    const {
      order_no,
      pay_phone,
      venue,
      time, // 
      // finish_time, // 完成时间
    } = form || {};


    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    if (order_no) {
      outerParams.order_no = order_no;
    }

    if (pay_phone) {
      outerParams.pay_phone = pay_phone
    }

    if (venue && venue.id) {
      outerParams.shop_id = venue.id
    }

    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      outerParams.over_start_time = startTime;

      outerParams.over_end_time = endTime;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.over_start_time && outerParams.over_end_time)
    ) {
      this.$message.error("请选择日期导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;

      // outerParams.__isUnique = !firstLoading;
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  /** 搜索店铺 */
  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.cashierOrderListApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        // searchStu((res) => {
        //   const {
        //     students,
        //     total_count
        //   } = res || {}

        //   this.remoteAllStudents = [
        //     ...(students || [])
        //   ]

        //   this.remoteStudentTotalCount = total_count || 0

        //   this.remoteGetStudentLoading = false
        // }, {
        //   kw: query,
        //   ...this.getRemoteGetStudentPageOptionParams()
        // }, () => {
        //   this.remoteGetStudentLoading = false
        // })
      }, 300);
      // setTimeout(() => {
      //   this.remoteGetStudentLoading = false;
      //   this.options = this.list
      // }, 200);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 字段名 文案对应关系
  private cashierOrderExcelList2ch: any = {
    "shop_name": "店铺名称",
    "order_no": "订单号",
    "pool_name": "球桌名",
    "__tablePayType": "球桌开台支付类型",
    "__tableUseDuration": "球桌使用时间",
    "price": "球桌消费",
    "__goods_info_str": "商品信息",
    "__assitant_info_str": "助教信息",
    "pay_phone": "手机号",
    "__pay_total_method": "支付方式",
    "total_price": "总金额（元）",
    "actual_total_price": "实际支付金额（元）"
  };
  // 商户Excel 表头的先后顺序
  private cashierOrderExcelSortList: string[] = [
    "shop_name",
    "order_no",
    "pool_name",
    "__tablePayType",
    "__tableUseDuration",
    "price",
    "__goods_info_str",
    "__assitant_info_str",
    "pay_phone",
    "__pay_total_method",
    "total_price",
    "actual_total_price"
  ];

  // 点击导出excel 表格
  private handleExportExcelCashierOrderList() {
    const params = this.getWithdrawAccountAuditParams('exportExcel')

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.cashierOrderListApi.exportCashierOrderList(
      params,
      (res: any) => {
        const { over_start_time: start_date, over_end_time: end_date } = params || {};

        const filename = `收银台订单列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const {
          list: originList
        } = data || {}

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { 
                type,
                start_time,
                over_time,
                good_list,
                assistant_list,
                cashier_pay,
                cashier_pay2
               } = item || {};

              let __goods_info_str = "";

              for (let i = 0; i < good_list.length; i++) {
                const goods = good_list[i];
                __goods_info_str += `${goods.name}x${goods.num} 消费：¥${
                  goods.price
                }; `;
              }

              let __assitant_info_str = ""

              for (let i = 0; i < assistant_list.length; i++) {
                const assistant = assistant_list[i];
                __assitant_info_str += `助教：${assistant.name} 时间：${assistant.start_time}~${assistant.over_time} 消费：¥${
                  assistant.total_price
                }; `;
              }

              let __pay_total_method = ''

              if (cashier_pay2 == 0) {
                __pay_total_method = cashierPay2ch[cashier_pay]
              } else {
                __pay_total_method = `${cashierPay2ch[cashier_pay]} + ${cashier2Pay2ch[cashier_pay2]}`
              }

              const newItem = {
                ...(item || {}),
                // __status: withdrawAccountAudit2Text[status]
                __tablePayType: payMode2ch[type],
                __tableUseDuration: `${start_time}~${over_time}`,
                __goods_info_str,
                __assitant_info_str,
                __pay_total_method
              };

              for (let key in item) {
                if (typeof item[key] == 'number') {
                  newItem[key] = item[key].toString()
                }
              }

             
              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const cashierOrderExcelSortList = this.cashierOrderExcelSortList;

          const cashierOrderExcelList2ch = this.cashierOrderExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            cashierOrderExcelSortList,
            cashierOrderExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
}
